@keyframes loadingSpinnerWheel {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
}

.loadingSpinner {
    display: inline-block;
    overflow: hidden;
    background: none;
    vertical-align: middle;
    margin: 10px auto;
}

.loadingSpinnerWheel {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.loadingSpinnerWheelInternal {
    position: absolute;
    animation: loadingSpinnerWheel 1s linear infinite;
    width: 80%;
    height: 80%;
    top: 10%;
    left: 10%;
    border-radius: 50%;
    /*box-shadow: 0 2px 0 0 #e15b64;*/
    box-sizing: content-box;
}
