* {box-sizing: border-box}

/* style the container */
.login-container {
    position: relative;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px 0 30px 0;
    max-width: 1024px;
}

/* style inputs and link buttons */
input,
.btn {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    opacity: 0.85;
    display: inline-block;
    font-size: 15px;
    line-height: 16px;
}

input:hover,
.btn:hover {
    opacity: 1;
}

/* add appropriate colors to fb, twitter and google buttons */
.fb {
    background-color: #3B5998;
    color: white;
}

.google {
    background-color: #dd4b39;
    color: white;
}

/* style the submit button */
input[type=submit] {
    background-color: #04AA6D;
    color: white;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

/* Two-column layout */
.col {
    float: left;
    width: 100%;
    padding: 0 50px;
    margin: 6px auto auto;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}